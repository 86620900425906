.badge-item {
    position: relative;
    padding-top: 10px;
    display: inline-block;
}

.counter-badge {
    white-space: nowrap;
    position: absolute;
    left: 10px;
    top: 0;
    background: cornflowerblue;
    text-align: center;
    border: 1px solid white;
    border-radius: 20px;
    color: white;
    padding: 0 4px;
    font-size: 10px;
}

.level-statistics-bar-chart > canvas {
    width: 100% !important;
    height: auto !important;
    max-height: 300px !important;
}

.hoverable:hover {
    cursor: pointer;
}

.hover-primary-color:hover {
    color: #5e72e4 !important;
}

.hover-secondary:hover {
    /*color: rgba(244, 245, 247, 1) !important;*/
    background-color: rgba(244, 245, 247, 1) !important;
}

/* override multi-select default styles */
.multiSelectContainer input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #8898aa
}

.multiselect-container {
    border-radius: 0.375rem !important;
}

.optionContainer > .option {
    font-weight: 400 !important;
    line-height: 1.5 !important;
    font-size: .875rem !important;
}

.optionContainer > .option:hover {
    color: white !important;
    background-color: #32325d !important;
}

.highlightOption {
    font-weight: 400 !important;
    line-height: 1.5 !important;
    font-size: .875rem !important;
}

.highlightOption.highlight {
    /*background-color: #5e72e4 !important;*/
    color: #32325d !important;
    background-color: white !important;
    margin: 0 !important;
}

/* override multi-select default styles */

@media (min-width: 768px) {
    .navbar-vertical.navbar-expand-md .navbar-brand-img {
        max-height: 4.5rem;
    }

    .navbar-vertical.navbar-expand-md .navbar-brand {
        padding-bottom: 0;
    }
}

/* Checkbox styles */
.custom-checkbox-container {
    position: absolute;
    bottom: 0;
}

.custom-checkbox {
    display: none;
}

.custom-checkbox + label {
    display: block;
    position: relative;
    padding-left: 35px;
    /*color: #495057;*/
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.custom-checkbox + label:last-child {
    margin-bottom: 0;
}

.custom-checkbox + label:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    border: 2px solid #5e72e4;
    /*box-shadow: 0 0 28px rgba(94, 114, 228, 0.2);*/
    position: absolute;
    left: 0;
    top: 0;
    opacity: .6;
    -webkit-transition: all .12s, border-color .08s;
    transition: all .12s, border-color .08s;
}

.custom-checkbox:checked + label:before {
    width: 10px;
    top: -5px;
    left: 5px;
    border-radius: 0;
    border: 3px solid #5e72e4;
    /*box-shadow: 0 4px 28px rgba(94, 114, 228, 0.4);*/
    opacity: 1;
    border-top-color: transparent;
    border-left-color: transparent;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* Checkbox styles */

.rdt_TableHeadRow {
    color: #8898aa !important;
    font-weight: 800 !important;
    background-color: #f6f9fc !important;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 0.65rem;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.rdt_TableCell .text-center {
    text-align: center !important;
}
.rdt_TableRow {
    border-color: #e9ecef !important;
}
.sc-ikJyIC {
    display: none !important;
}

.sc-llYSUQ {
    display: none !important;
}

.kUKiTL, .bVYdcq {
    padding-right: 5px !important;
    padding-left: 5px !important;
}

.ldeolY div:first-child {
    white-space: nowrap !important;
    overflow: visible !important;
    text-overflow: ellipsis !important;
}

.navbar-horizontal .navbar-brand img {
    height: 50px;
}

.auth-navbar-img {
    height: 75px !important;
}

.custom-label {
    font-weight: 400;
    line-height: 1.5;
    font-size: 0.875rem;
}

.fs-12 {
    font-size: 12px;
}

/* Always on toggle */
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 22px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.switch-slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .switch-slider {
    background-color: #2196F3;
}

input:not(:checked) + .always-on {
    background-color: #2196F3;
}

input:focus + .switch-slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .switch-slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.switch-slider.round {
    border-radius: 34px;
}

.switch-slider.round:before {
    border-radius: 50%;
}

/* Always on toggle end */